<template>
  <img :src="dataUrl" :alt="alt" />
</template>

<script>
import QRious from 'qrious'

// QR Code error correction levels
const LEVELS = ['L', 'M', 'Q', 'H']

export default {
  name: 'QrCode',

  props: {
    value: {
      type: String,
      required: true,
    },

    alt: {
      type: String,
      default: '',
    },

    background: {
      type: String,
      default: '#ffffff',
    },

    backgroundAlpha: {
      type: Number,
      default: 1.0,
    },

    foreground: {
      type: String,
      default: '#000000',
    },

    foregroundAlpha: {
      type: Number,
      default: 1.0,
    },

    level: {
      type: String,
      default: 'M',
      validator: (level) => LEVELS.includes(level),
    },

    mime: {
      type: String,
      default: 'image/png',
    },

    padding: {
      type: Number,
      default: 0,
    },

    size: {
      type: Number,
      default: 512,
    },
  },

  /**
   * @returns {{dataUrl: null, qrious: null}}
   */
  data() {
    return {
      qrious: null,
      dataUrl: null,
    }
  },

  /**
   * Trigger QR Code update when relevant props change
   */
  watch: {
    value: 'updateQRCode',
    background: 'updateQRCode',
    backgroundAlpha: 'updateQRCode',
    foreground: 'updateQRCode',
    foregroundAlpha: 'updateQRCode',
    level: 'updateQRCode',
    mime: 'updateQRCode',
    padding: 'updateQRCode',
    size: 'updateQRCode',
  },

  /**
   * Initialize QRious and generate the initial QR Code
   */
  mounted() {
    this.initQRCode()
  },

  methods: {
    /**
     * Initialize QRious and generate the initial QR Code
     */
    initQRCode() {
      this.qrious = new QRious({
        value: this.value,
        background: this.background,
        backgroundAlpha: this.backgroundAlpha,
        foreground: this.foreground,
        foregroundAlpha: this.foregroundAlpha,
        level: this.level,
        mime: this.mime,
        padding: this.padding,
        size: this.size,
      })
      this.updateQRCode()
    },

    /**
     * Update QRious instance with new data and refresh the data URL
     */
    updateQRCode() {
      if (this.qrious) {
        this.qrious.set({
          value: this.value,
          background: this.background,
          backgroundAlpha: this.backgroundAlpha,
          foreground: this.foreground,
          foregroundAlpha: this.foregroundAlpha,
          level: this.level,
          mime: this.mime,
          padding: this.padding,
          size: this.size,
        })
        this.refreshDataURL()
      }
    },

    /**
     * Refresh the QR Code data URL and emit the new data URL
     */
    refreshDataURL() {
      try {
        const dataUrl = this.qrious.toDataURL(this.mime)
        this.dataUrl = dataUrl
        this.$emit('change', dataUrl)
      } catch (err) {
        this.$emit('error', err)
        this.dataUrl = null
      }
    },
  },
}
</script>
