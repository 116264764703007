<template>
  <ThankYouPage />
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import ThankYouPage from 'chimera/all/themes/blueflow/page/ThankYou/ThankYouPage'

export default {
  components: {
    ThankYouPage,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Aanvraag afgerond',
      headDescription:
        'Vul je postcode in en vergelijk geheel gratis 6 offertes van top bedrijven uit jouw regio. ✓Dé beste prijs voor jouw isolatie specialist ✓Bespaar tot 40% ✓100% Gratis!',
      path: '/offertes-aanvragen/aanvraag-afgerond',
    }
  },
}
</script>
