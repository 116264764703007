<template>
  <div
    class="page-thank-you__header"
    :class="{ 'page-thank-you__header--match': companies.length > 0 }"
  >
    <div class="page-thank-you__content">
      <div v-if="companies.length > 0">
        <h1 class="page-thank-you__title">{{ $t('title') }}</h1>
        <p class="page-thank-you__subtitle">
          {{ $t('subtitle') }}
        </p>

        <div class="page-thank-you__info">
          <div class="page-thank-you__image">
            <img :src="image" width="280" height="280" :alt="$t('title')" />
          </div>

          <div
            v-if="companies.length > 0"
            class="page-thank-you__business-cards"
          >
            <BusinessCards :companies="companies" />
          </div>
        </div>

        <p class="page-thank-you__compare-text">
          <span class="compare-text__line">
            {{ $t('compare_text_1') }}
          </span>
          <span class="compare-text__line">
            {{ $t('compare_text_2') }}
          </span>
          <span class="compare-text__line">
            {{ $t('compare_text_3') }}
          </span>
        </p>
      </div>

      <NoMatch v-else />
    </div>
  </div>
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import NoMatch from 'chimera/all/page/thankYou/NoMatch.vue'
import BusinessCards from './BusinessCards.vue'

export default {
  components: {
    NoMatch,
    BusinessCards,
  },

  extends: AbstractPage,

  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      image: require('chimera/all/assets/images/handshake.png'),
      noMatchImage: require('chimera/all/assets/images/searching.png'),
    }
  },
}
</script>

<i18n>
{
  "nl": {
    "title": "We hebben met zorg de beste specialisten voor je geselecteerd!",
    "subtitle": "Binnen enkele dagen nemen deze specialisten contact met je op om je een persoonlijke offerte aan te bieden.",
    "compare_text_1": "Vergelijk de aanbiedingen op je gemak en kies de optie die het beste bij je past.",
    "compare_text_2": "Je kunt hun referenties eenvoudig controleren met de contactgegevens die wij hebben verstrekt.",
    "compare_text_3": "Wij helpen je graag met het vinden van de juiste specialist!",
    "no_match_title": "Sorry, we hebben nog geen match kunnen vinden",
    "no_match_1": "Op dit moment kunnen we je verzoek helaas niet matchen met een geschikte specialist in ons netwerk. We hopen je snel een bredere selectie van specialisten aan te kunnen bieden.",
    "no_match_2": "Mocht er in de komende dagen toch een match beschikbaar komen, dan nemen we direct contact met je op!"
  },
  "nl-BE": {
    "title": "We hebben met zorg de beste specialisten voor u geselecteerd!",
    "subtitle": "Binnen enkele dagen nemen deze specialisten contact met u op om u een persoonlijke offerte aan te bieden.",
    "compare_text_1": "Vergelijk de aanbiedingen op uw gemak en kies de optie die het beste bij u past.",
    "compare_text_2": "U kunt hun referenties eenvoudig controleren met de contactgegevens die wij hebben verstrekt.",
    "compare_text_3": "Wij helpen u graag met het vinden van de juiste specialist!",
    "no_match_title": "Helaas geen match gevonden",
    "no_match_1": "Op dit moment kunnen we uw verzoek helaas niet matchen met een geschikte specialist in ons netwerk. We hopen u snel een bredere selectie van specialisten aan te kunnen bieden.",
    "no_match_2": "Mocht er in de komende dagen toch een match beschikbaar komen, dan nemen we direct contact met u op!"
  },
  "es-ES": {
    "title": "¡Hemos seleccionado cuidadosamente los mejores especialistas para ti!",
    "subtitle": "En unos días, estos especialistas se pondrán en contacto contigo para ofrecerte un presupuesto personalizado.",
    "compare_text_1": "Compara las ofertas con tranquilidad y elige la opción que mejor se adapte a ti.",
    "compare_text_2": "Puedes verificar fácilmente sus referencias con los datos de contacto que te hemos proporcionado.",
    "compare_text_3": "¡Nos complace ayudarte a encontrar el especialista adecuado!",
    "no_match_title": "Lamentablemente no hemos encontrado coincidencias",
    "no_match_1": "En este momento, lamentablemente no podemos emparejar tu solicitud con un especialista adecuado en nuestra red. Esperamos poder ofrecerte pronto una selección más amplia de especialistas.",
    "no_match_2": "Si en los próximos días aparece un emparejamiento disponible, nos pondremos en contacto contigo de inmediato."
  },
  "de-DE": {
    "title": "Wir haben sorgfältig die besten Spezialisten für Sie ausgewählt!",
    "subtitle": "Innerhalb weniger Tage werden diese Spezialisten Sie kontaktieren, um Ihnen ein persönliches Angebot zu unterbreiten.",
    "compare_text_1": "Vergleichen Sie die Angebote in Ruhe und wählen Sie die Option, die am besten zu Ihnen passt.",
    "compare_text_2": "Sie können ihre Referenzen einfach mit den von uns bereitgestellten Kontaktdaten überprüfen.",
    "compare_text_3": "Wir helfen Ihnen gerne dabei, den richtigen Spezialisten zu finden!",
    "no_match_title": "Leider kein Match gefunden",
    "no_match_1": "Derzeit können wir Ihre Anfrage leider nicht mit einem geeigneten Spezialisten in unserem Netzwerk abgleichen. Wir hoffen, Ihnen bald eine breitere Auswahl an Spezialisten anbieten zu können.",
    "no_match_2": "Sollte in den nächsten Tagen doch ein Match verfügbar sein, werden wir uns umgehend mit Ihnen in Verbindung setzen."
  },
  "fr-BE": {
    "title": "Nous avons soigneusement sélectionné les meilleurs spécialistes pour vous !",
    "subtitle": "Ces spécialistes vous contacteront dans quelques jours pour vous proposer un devis personnalisé.",
    "compare_text_1": "Comparez les offres à votre rythme et choisissez l'option qui vous convient le mieux.",
    "compare_text_2": "Vous pouvez facilement vérifier leurs références avec les coordonnées que nous vous avons fournies.",
    "compare_text_3": "Nous sommes heureux de vous aider à trouver le spécialiste adéquat !",
    "no_match_title": "Aucune correspondance trouvée",
    "no_match_1": "Actuellement, nous ne pouvons malheureusement pas faire correspondre votre demande à un spécialiste adéquat dans notre réseau. Nous espérons pouvoir vous proposer bientôt une sélection plus large de spécialistes.",
    "no_match_2": "Si une correspondance est disponible dans les prochains jours, nous vous contacterons immédiatement."
  },
  "fr-FR": {
    "title": "Nous avons soigneusement sélectionné les meilleurs spécialistes pour vous !",
    "subtitle": "Ces spécialistes vous contacteront dans quelques jours pour vous proposer un devis personnalisé.",
    "compare_text_1": "Comparez les offres à votre rythme et choisissez l'option qui vous convient le mieux.",
    "compare_text_2": "Vous pouvez facilement vérifier leurs références avec les coordonnées que nous vous avons fournies.",
    "compare_text_3": "Nous sommes heureux de vous aider à trouver le spécialiste adéquat !",
    "no_match_title": "Aucune correspondance trouvée",
    "no_match_1": "Actuellement, nous ne pouvons malheureusement pas faire correspondre votre demande à un spécialiste adéquat dans notre réseau. Nous espérons pouvoir vous proposer bientôt une sélection plus large de spécialistes.",
    "no_match_2": "Si une correspondance est disponible dans les prochains jours, nous vous contacterons immédiatement."
  },
  "it-IT": {
    "title": "Abbiamo selezionato con cura i migliori specialisti per te!",
    "subtitle": "Entro pochi giorni, questi specialisti ti contatteranno per offrirti un preventivo personalizzato.",
    "compare_text_1": "Confronta le offerte con calma e scegli l'opzione più adatta a te.",
    "compare_text_2": "Puoi verificare facilmente i loro riferimenti con i contatti che ti abbiamo fornito.",
    "compare_text_3": "Siamo lieti di aiutarti a trovare lo specialista giusto!",
    "no_match_title": "Purtroppo nessuna corrispondenza trovata",
    "no_match_1": "Attualmente, purtroppo non possiamo abbinare la tua richiesta a uno specialista adeguato nella nostra rete. Speriamo di poterti offrire presto una selezione più ampia di specialisti.",
    "no_match_2": "Se un abbinamento sarà disponibile nei prossimi giorni, ti contatteremo immediatamente."
  }
}
</i18n>
