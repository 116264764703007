<template>
  <div class="business-cards">
    <div
      v-for="(company, index) in companies"
      :id="`business-card-${index}`"
      :key="company['display-name']"
      v-track-visibility="getVisibilityMetadata(index, 'businessCard')"
      class="card"
    >
      <div class="card-body">
        <h3 class="card-title">{{ company['display-name'] }}</h3>
      </div>

      <div class="card-footer">
        <div v-if="showQRCodeMap[index]" class="card-qrcode">
          <QrCode
            v-track-visibility="getVisibilityMetadata(index, 'QRCode')"
            v-tippy="{ content: $t('scan-qr'), placement: 'top' }"
            :size="1024"
            :value="getVCardData(company, index)"
          />
        </div>

        <div v-else class="card-links">
          <a
            v-if="company.address"
            :id="`business-card-${index}-address`"
            v-track-click="{
              metadata: {
                linkType: 'address',
                elementIndex: index,
                elementGroup: 'businessCard',
              },
            }"
            :href="`http://www.google.com/search?q=${formattedAddress(company.address)}`"
            target="_blank"
            class="card-link"
          >
            <t-icon
              height="16"
              width="16"
              :icon="addressIcon"
              class="card-link__icon"
            />
            <span>{{ formattedAddress(company.address) }}</span>
          </a>
          <a
            v-if="company.website"
            :id="`business-card-${index}-website`"
            v-track-click="{
              metadata: {
                linkType: 'website',
                elementIndex: index,
                elementGroup: 'businessCard',
              },
            }"
            :href="company.website"
            target="_blank"
            :title="`${company.website}`"
            class="card-link card-link__website"
          >
            <t-icon
              height="16"
              width="16"
              :icon="websiteIcon"
              class="card-link__icon"
            />
            <span class="link-text">{{ extractDomain(company.website) }}</span>
          </a>
          <a
            v-if="company.email"
            :id="`business-card-${index}-email`"
            v-track-click="{
              metadata: {
                linkType: 'email',
                elementIndex: index,
                elementGroup: 'businessCard',
              },
            }"
            :href="`mailto:${company.email}`"
            target="_blank"
            class="card-link card-link__email"
          >
            <t-icon
              height="16"
              width="16"
              :icon="emailIcon"
              class="card-link__icon"
            />
            <span class="link-text">{{ company.email }}</span>
          </a>
          <a
            v-if="company.phone"
            :id="`business-card-${index}-phone`"
            v-track-click="{
              metadata: {
                linkType: 'phone',
                elementIndex: index,
                elementGroup: 'businessCard',
              },
            }"
            :href="`tel:${company.phone}`"
            target="_blank"
            class="card-link card-link__phone"
          >
            <t-icon
              height="16"
              width="16"
              :icon="phoneIcon"
              class="card-link__icon"
            />
            <span>{{ company.phone }}</span>
          </a>
        </div>

        <div class="card-download-links-row">
          <button
            :id="`business-card-${index}-vcard-download`"
            v-track-click="{
              metadata: {
                linkType: 'downloadVCard',
                elementIndex: index,
                elementGroup: 'businessCard',
              },
            }"
            class="w-full t-btn t-btn--medium card-download-button"
            @click="downloadVCard(company)"
          >
            {{ $t('btn-text') }}
          </button>

          <button
            v-if="!isMobile"
            :id="`business-card-${index}-qr-code`"
            :key="`business-card-${index}-qr-code-${showQRCodeMap[index]}`"
            v-tippy="{ content: $t('scan-qr-hint'), placement: 'top' }"
            v-track-click="{
              metadata: {
                linkType: 'scanQRCode',
                linkAction: getQRCodeLinkAction(index),
                elementIndex: index,
                elementGroup: 'businessCard',
              },
            }"
            type="button"
            class="group card-scan-qr-code"
            @click="toggleQRCode(index)"
          >
            <t-icon
              v-if="!showQRCodeMap[index]"
              height="24"
              width="24"
              :icon="scanQRIcon"
            />
            <t-icon v-else height="24" width="24" :icon="closeIcon" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiEmail,
  mdiMapMarker,
  mdiPhone,
  mdiQrcodeScan,
  mdiWeb,
} from '@mdi/js'
import QrCode from 'chimera/all/components/QRCode.vue'
import VCard from 'vcard-creator'
import MobileDetect from 'mobile-detect'

export default {
  name: 'BusinessCards',

  components: {
    QrCode,
  },

  props: {
    companies: {
      type: Array,
      required: true,
    },
  },

  /**
   * @returns {{websiteIcon: string, vCardCache: {}, phoneIcon: string, scanQRIcon: string, showQRCodeMap: {}, emailIcon: string, closeIcon: string, isMobile: boolean, addressIcon: string}}
   */
  data() {
    return {
      addressIcon: mdiMapMarker,
      emailIcon: mdiEmail,
      phoneIcon: mdiPhone,
      websiteIcon: mdiWeb,
      scanQRIcon: mdiQrcodeScan,
      closeIcon: mdiClose,
      isMobile: false,
      showQRCodeMap: {},
      vCardCache: {},
    }
  },

  /**
   *
   */
  beforeMount() {
    const md = new MobileDetect(window.navigator.userAgent)
    this.isMobile = md.mobile()
  },

  methods: {
    /**
     * @param {number} index - The index of the element.
     * @param {string} elementGroup - The group identifier for the element.
     * @returns {object} - The tracking metadata.
     */
    getVisibilityMetadata(index, elementGroup) {
      return {
        metadata: {
          elementIndex: index,
          elementGroup,
        },
      }
    },

    /**
     * @param {number} index
     */
    toggleQRCode(index) {
      this.$set(this.showQRCodeMap, index, !this.showQRCodeMap[index])
    },

    /**
     * Uses the QR code visibility to determine the action the button plays.
     *
     * @param {number} index
     * @returns {string}
     */
    getQRCodeLinkAction(index) {
      return this.showQRCodeMap[index] ? 'hide' : 'show'
    },

    /**
     * @param {object} company
     * @param {number} index
     * @returns {*}
     */
    getVCardData(company, index) {
      if (!this.vCardCache[index]) {
        const vCard = new VCard()
          .addCompany(company['display-name'])
          .addEmail(company.email, 'WORK')
          .addPhoneNumber(company.phone, 'PREF;WORK')
          .addAddress(
            '',
            '',
            `${company.address.address} ${company.address['house-number']}${company.address['house-number-addition'] || ''}`,
            company.address.city,
            '',
            company.address.postal,
            company.address.country.toUpperCase(),
            'WORK',
          )

        if (company.website) {
          vCard.addURL(company.website)
        }

        this.$set(this.vCardCache, index, vCard.toString())
      }
      return this.vCardCache[index]
    },

    /**
     * @param {object} address
     * @returns {string}
     */
    formattedAddress(address) {
      const {
        address: street,
        'house-number': houseNumber,
        'house-number-addition': houseNumberAddition,
        postal,
        city,
      } = address
      return `${street} ${houseNumber}${houseNumberAddition || ''}, ${postal} ${city}`
    },

    /**
     * @param {object} company
     */
    downloadVCard(company) {
      const vCardData = this.getVCardData(company)
      const blob = new Blob([vCardData], { type: 'text/vcard;charset=utf-8' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${company['display-name']}.vcf`
      link.click()
      URL.revokeObjectURL(url)
    },
    /**
     * Extracts the domain from a given URL.
     *
     * @param {string} url - The URL to extract the domain from.
     * @returns {string} - The extracted domain.
     */
    extractDomain(url) {
      try {
        const { hostname } = new URL(url)
        return hostname.replace('www.', '')
      } catch (e) {
        return url
      }
    },
  },
}
</script>

<i18n>
{
  "nl": {
    "btn-text": "Bewaar contactgegevens",
    "scan-qr": "Scan de QR-code om de contactgegevens op te slaan",
    "scan-qr-hint": "Toon QR-code met contactgegevens"
  },
  "nl-BE": {
    "btn-text": "Bewaar contactgegevens",
    "scan-qr": "Scan de QR-code om de contactgegevens op te slaan",
    "scan-qr-hint": "Toon QR-code met contactgegevens"
  },
  "es-ES": {
    "btn-text": "Guardar detalles de contacto",
    "scan-qr": "Escanee el código QR para guardar los detalles de contacto",
    "scan-qr-hint": "Mostrar código QR con detalles de contacto"
  },
  "de-DE": {
    "btn-text": "Kontaktdetails speichern",
    "scan-qr": "Scannen Sie den QR-Code, um die Kontaktdaten zu speichern",
    "scan-qr-hint": "QR-Code mit Kontaktdaten anzeigen"
  },
  "fr-BE": {
    "btn-text": "Enregistrer les détails du contact",
    "scan-qr": "Scannez le code QR pour enregistrer les détails du contact",
    "scan-qr-hint": "Afficher le code QR avec les détails du contact"
  },
  "fr-FR": {
    "btn-text": "Enregistrer les détails du contact",
    "scan-qr": "Scannez le code QR pour enregistrer les détails du contact",
    "scan-qr-hint": "Afficher le code QR avec les détails du contact"
  },
  "it-IT": {
    "btn-text": "Salva i dettagli del contatto",
    "scan-qr": "Scansiona il codice QR per salvare i dettagli del contatto",
    "scan-qr-hint": "Mostra il codice QR con i dettagli del contatto"
  }
}
</i18n>
