var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business-cards"},_vm._l((_vm.companies),function(company,index){return _c('div',{directives:[{name:"track-visibility",rawName:"v-track-visibility",value:(_vm.getVisibilityMetadata(index, 'businessCard')),expression:"getVisibilityMetadata(index, 'businessCard')"}],key:company['display-name'],staticClass:"card",attrs:{"id":`business-card-${index}`}},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"card-title"},[_vm._v(_vm._s(company['display-name']))])]),_vm._v(" "),_c('div',{staticClass:"card-footer"},[(_vm.showQRCodeMap[index])?_c('div',{staticClass:"card-qrcode"},[_c('QrCode',{directives:[{name:"track-visibility",rawName:"v-track-visibility",value:(_vm.getVisibilityMetadata(index, 'QRCode')),expression:"getVisibilityMetadata(index, 'QRCode')"},{name:"tippy",rawName:"v-tippy",value:({ content: _vm.$t('scan-qr'), placement: 'top' }),expression:"{ content: $t('scan-qr'), placement: 'top' }"}],attrs:{"size":1024,"value":_vm.getVCardData(company, index)}})],1):_c('div',{staticClass:"card-links"},[(company.address)?_c('a',{directives:[{name:"track-click",rawName:"v-track-click",value:({
            metadata: {
              linkType: 'address',
              elementIndex: index,
              elementGroup: 'businessCard',
            },
          }),expression:"{\n            metadata: {\n              linkType: 'address',\n              elementIndex: index,\n              elementGroup: 'businessCard',\n            },\n          }"}],staticClass:"card-link",attrs:{"id":`business-card-${index}-address`,"href":`http://www.google.com/search?q=${_vm.formattedAddress(company.address)}`,"target":"_blank"}},[_c('t-icon',{staticClass:"card-link__icon",attrs:{"height":"16","width":"16","icon":_vm.addressIcon}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.formattedAddress(company.address)))])],1):_vm._e(),_vm._v(" "),(company.website)?_c('a',{directives:[{name:"track-click",rawName:"v-track-click",value:({
            metadata: {
              linkType: 'website',
              elementIndex: index,
              elementGroup: 'businessCard',
            },
          }),expression:"{\n            metadata: {\n              linkType: 'website',\n              elementIndex: index,\n              elementGroup: 'businessCard',\n            },\n          }"}],staticClass:"card-link card-link__website",attrs:{"id":`business-card-${index}-website`,"href":company.website,"target":"_blank","title":`${company.website}`}},[_c('t-icon',{staticClass:"card-link__icon",attrs:{"height":"16","width":"16","icon":_vm.websiteIcon}}),_vm._v(" "),_c('span',{staticClass:"link-text"},[_vm._v(_vm._s(_vm.extractDomain(company.website)))])],1):_vm._e(),_vm._v(" "),(company.email)?_c('a',{directives:[{name:"track-click",rawName:"v-track-click",value:({
            metadata: {
              linkType: 'email',
              elementIndex: index,
              elementGroup: 'businessCard',
            },
          }),expression:"{\n            metadata: {\n              linkType: 'email',\n              elementIndex: index,\n              elementGroup: 'businessCard',\n            },\n          }"}],staticClass:"card-link card-link__email",attrs:{"id":`business-card-${index}-email`,"href":`mailto:${company.email}`,"target":"_blank"}},[_c('t-icon',{staticClass:"card-link__icon",attrs:{"height":"16","width":"16","icon":_vm.emailIcon}}),_vm._v(" "),_c('span',{staticClass:"link-text"},[_vm._v(_vm._s(company.email))])],1):_vm._e(),_vm._v(" "),(company.phone)?_c('a',{directives:[{name:"track-click",rawName:"v-track-click",value:({
            metadata: {
              linkType: 'phone',
              elementIndex: index,
              elementGroup: 'businessCard',
            },
          }),expression:"{\n            metadata: {\n              linkType: 'phone',\n              elementIndex: index,\n              elementGroup: 'businessCard',\n            },\n          }"}],staticClass:"card-link card-link__phone",attrs:{"id":`business-card-${index}-phone`,"href":`tel:${company.phone}`,"target":"_blank"}},[_c('t-icon',{staticClass:"card-link__icon",attrs:{"height":"16","width":"16","icon":_vm.phoneIcon}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(company.phone))])],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"card-download-links-row"},[_c('button',{directives:[{name:"track-click",rawName:"v-track-click",value:({
            metadata: {
              linkType: 'downloadVCard',
              elementIndex: index,
              elementGroup: 'businessCard',
            },
          }),expression:"{\n            metadata: {\n              linkType: 'downloadVCard',\n              elementIndex: index,\n              elementGroup: 'businessCard',\n            },\n          }"}],staticClass:"w-full t-btn t-btn--medium card-download-button",attrs:{"id":`business-card-${index}-vcard-download`},on:{"click":function($event){return _vm.downloadVCard(company)}}},[_vm._v("\n          "+_vm._s(_vm.$t('btn-text'))+"\n        ")]),_vm._v(" "),(!_vm.isMobile)?_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({ content: _vm.$t('scan-qr-hint'), placement: 'top' }),expression:"{ content: $t('scan-qr-hint'), placement: 'top' }"},{name:"track-click",rawName:"v-track-click",value:({
            metadata: {
              linkType: 'scanQRCode',
              linkAction: _vm.getQRCodeLinkAction(index),
              elementIndex: index,
              elementGroup: 'businessCard',
            },
          }),expression:"{\n            metadata: {\n              linkType: 'scanQRCode',\n              linkAction: getQRCodeLinkAction(index),\n              elementIndex: index,\n              elementGroup: 'businessCard',\n            },\n          }"}],key:`business-card-${index}-qr-code-${_vm.showQRCodeMap[index]}`,staticClass:"group card-scan-qr-code",attrs:{"id":`business-card-${index}-qr-code`,"type":"button"},on:{"click":function($event){return _vm.toggleQRCode(index)}}},[(!_vm.showQRCodeMap[index])?_c('t-icon',{attrs:{"height":"24","width":"24","icon":_vm.scanQRIcon}}):_c('t-icon',{attrs:{"height":"24","width":"24","icon":_vm.closeIcon}})],1):_vm._e()])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }