<template>
  <div class="no-match">
    <div class="image">
      <img
        :src="noMatchImage"
        width="280"
        height="280"
        :alt="$t('no_match_title')"
      />
    </div>
    <div class="content">
      <h1 class="content__title">{{ $t('no_match_title') }}</h1>
      <p class="content__text">
        {{ $t('no_match_1') }}
      </p>
      <p class="content__text">
        <strong>{{ $t('no_match_2') }}</strong>
      </p>
    </div>
  </div>
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'

export default {
  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      noMatchImage: require('chimera/all/assets/images/searching.png'),
    }
  },
}
</script>

<i18n>
{
  "nl": {
    "no_match_title": "Sorry, we hebben nog geen match kunnen vinden",
    "no_match_1": "Op dit moment kunnen we je verzoek helaas niet matchen met een geschikte specialist in ons netwerk. We hopen je snel een bredere selectie van specialisten aan te kunnen bieden.",
    "no_match_2": "Mocht er in de komende dagen toch een match beschikbaar komen, dan nemen we direct contact met je op!"
  },
  "nl-BE": {
    "no_match_title": "Helaas geen match gevonden",
    "no_match_1": "Op dit moment kunnen we uw verzoek helaas niet matchen met een geschikte specialist in ons netwerk. We hopen u snel een bredere selectie van specialisten aan te kunnen bieden.",
    "no_match_2": "Mocht er in de komende dagen toch een match beschikbaar komen, dan nemen we direct contact met u op!"
  },
  "es-ES": {
    "no_match_title": "Lamentablemente no hemos encontrado coincidencias",
    "no_match_1": "En este momento, lamentablemente no podemos emparejar tu solicitud con un especialista adecuado en nuestra red. Esperamos poder ofrecerte pronto una selección más amplia de especialistas.",
    "no_match_2": "Si en los próximos días aparece un emparejamiento disponible, nos pondremos en contacto contigo de inmediato."
  },
  "de-DE": {
    "no_match_title": "Leider kein Match gefunden",
    "no_match_1": "Derzeit können wir Ihre Anfrage leider nicht mit einem geeigneten Spezialisten in unserem Netzwerk abgleichen. Wir hoffen, Ihnen bald eine breitere Auswahl an Spezialisten anbieten zu können.",
    "no_match_2": "Sollte in den nächsten Tagen doch ein Match verfügbar sein, werden wir uns umgehend mit Ihnen in Verbindung setzen."
  },
  "fr-BE": {
    "no_match_title": "Aucune correspondance trouvée",
    "no_match_1": "Actuellement, nous ne pouvons malheureusement pas faire correspondre votre demande à un spécialiste adéquat dans notre réseau. Nous espérons pouvoir vous proposer bientôt une sélection plus large de spécialistes.",
    "no_match_2": "Si une correspondance est disponible dans les prochains jours, nous vous contacterons immédiatement."
  },
  "fr-FR": {
    "no_match_title": "Aucune correspondance trouvée",
    "no_match_1": "Actuellement, nous ne pouvons malheureusement pas faire correspondre votre demande à un spécialiste adéquat dans notre réseau. Nous espérons pouvoir vous proposer bientôt une sélection plus large de spécialistes.",
    "no_match_2": "Si une correspondance est disponible dans les prochains jours, nous vous contacterons immédiatement."
  },
  "it-IT": {
    "no_match_title": "Purtroppo nessuna corrispondenza trovata",
    "no_match_1": "Attualmente, purtroppo non possiamo abbinare la tua richiesta a uno specialista adeguato nella nostra rete. Speriamo di poterti offrire presto una selezione più ampia di specialisti.",
    "no_match_2": "Se un abbinamento sarà disponibile nei prossimi giorni, ti contatteremo immediatamente."
  }
}
</i18n>
